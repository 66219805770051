/* SkeletonLoader.css */

.skeleton-loader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .skeleton-header {
    width: 100%;
    height: 20px;
    background: #e0e0e0;
    border-radius: 4px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  .skeleton-image {
    width: 100%;
    height: 200px;
    background: #e0e0e0;
    border-radius: 8px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  .skeleton-text {
    width: 80%;
    height: 15px;
    background: #e0e0e0;
    border-radius: 4px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  .skeleton-button {
    width: 100px;
    height: 40px;
    background: #e0e0e0;
    border-radius: 4px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f5f5f5;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
  