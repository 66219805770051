.customShadow{
  box-shadow: 0px 0px 10px rgba(0, 0, 0,0.2);
}

.user-table th{
  @apply border text-base font-medium bg-white
}
.user-table td{
  @apply border text-base font-medium bg-white text-center
}

/* scroll hidden */
.scrollbar-none::-webkit-scrollbar{
  display: none;
}

/* Home.css */

@keyframes fall {
  0% {
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateX(100vw) rotate(720deg);
    opacity: 0;
  }
}

.leaf {
  position: absolute;
  top: -100px;
  left: 0;
  width: 20px;
  height: 20px;
  background-image: url('./assets/leaf.jpg'); /* Replace with the actual path to the leaf image */
  background-size: contain;
  background-repeat: no-repeat;
  animation: fall 5s linear infinite;
  opacity: 0;
}

.leaf:nth-child(odd) {
  animation-duration: 6s;
}

.leaf:nth-child(even) {
  animation-duration: 4s;
  animation-delay: 2s;
}


/* Nature-themed background */
.main {
  background: linear-gradient(to bottom, #E9F5E1, #F3F0D7); /* Soft green and yellow */
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

/* Falling leaves */
.falling-leaves-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.leaf {
  position: absolute;
  top: -100px;
  width: 30px;
  height: 30px;
  background-image: url('./assets/leaf.jpg'); /* Use a leaf image */
  background-size: cover;
  animation: fall 10s linear infinite;
}

@keyframes fall {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}

/* For different leaves to rotate and fall at different rates */
.leaf:nth-child(2) { animation-duration: 12s; }
.leaf:nth-child(3) { animation-duration: 9s; }
.leaf:nth-child(4) { animation-duration: 14s; }
.leaf:nth-child(5) { animation-duration: 11s; }

/* Product cards styling */
.product-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

/* Headings */
h2 {
  font-size: 1.75rem;
  color: #3D550C; /* Dark green */
  margin-bottom: 15px;
}

/* Category and other product details */
p {
  color: #6B705C; /* Muted green */
}

button {
  background-color: #8AA29E; /* Nature-inspired green */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #6A8E7F;
}

/* Responsive leaf falling effects */
@media (max-width: 768px) {
  .leaf {
    width: 20px;
    height: 20px;
  }
}
